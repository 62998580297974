<script>

export default {
	name: "TextInputCopy",
	props: {
		text: {
            type: String,
        }
	},
    data() {
        return {
            tippyConfig: {
                arrow: true,
                placement: 'bottom-start',
                theme: 'grey',
            }
        }
    },
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
    }
};
</script>

<template>
	<b-input-group prepend="">
        <b-form-input
            size="sm"
            :value="text"
            disabled
        ></b-form-input>
        <b-input-group-append>
            <b-button
                class="btn-copy"
                @click="copyText(text)"
                content="Copied to clipboard" 
                v-tippy="{
                    ...tippyConfig,
                    trigger : 'click'
                }"
            >
                copy
            </b-button>
        </b-input-group-append>
    </b-input-group>
</template>


<style lang="scss">
</style>
