<script>
import { component as VueCodeHighlight } from 'vue-code-highlight';
import 'prism-es6/components/prism-json';
import "../../public/css/prism-nord.css";

export default {
	name: "Code",
    components: {
        VueCodeHighlight
    },
    data() {
        return {
            tippyConfig: {
                arrow: true,
                placement: 'bottom-start',
                theme: 'grey',
            }
        }
    },
	props: {
		lang: {
            type: String,
        },
        code: {
            type: String,
        }
	},
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
    },
	computed: {
	},
    filters: {
		pretty: function (value) {
			return JSON.stringify(JSON.parse(value), null, 2);
		},
	}
};
</script>

<template>
	<div class="code-block">
        <b-button
            class="btn-copy"
            @click="copyText(code)"
            content="Copied to clipboard" 
            v-tippy="{
                ...tippyConfig,
                trigger : 'click'
            }"
            size="sm"
        >
            copy
        </b-button>
        <vue-code-highlight :language="lang">
            <pre>{{ code | pretty }}</pre>
        </vue-code-highlight>
	</div>
</template>


<style lang="scss">
    .code-block{
        position: relative;

        .btn-copy {
            position: absolute;
            right: 15px;
            top: 10px;
        }
    }
    pre {
        max-height: 350px !important;

        scrollbar-width: thin;
        background-color: #171717;
        overflow-x: hidden !important;

        padding: 10px;
    }
    pre::-webkit-scrollbar {
        width: 7px;

    }
    pre::-webkit-scrollbar-track {
        background-color: #282828;

        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    pre::-webkit-scrollbar-thumb {

        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background-clip: content-box;
        background-color: #585858;
    }

    code {
        white-space: pre-wrap !important; 
    }
</style>
