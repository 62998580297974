<script>
import Code from "@/components/Code.vue";
import TextInputCopy from "@/components/TextInputCopy.vue";

import random from "/public/api-sample/random.json";
import autocomplete from "/public/api-sample/autocomplete.json";
import lor from "/public/api-sample/lor.json";
import dualsubtypes from "/public/api-sample/dualsubtypes.json";
import enragedboars from "/public/api-sample/enragedboars.json";
import esteemedknightdemo from "/public/api-sample/esteemedknightdemo.json";
import flamesweep from "/public/api-sample/flamesweep.json";
import optionsearch from "/public/api-sample/optionsearch.json";

import { SlideXLeftTransition, SlideYUpTransition } from 'vue2-transitions'

// import config from "@/config";

export default {
	name: "API",
	components: {
		Code,
		TextInputCopy,
		SlideXLeftTransition,
		SlideYUpTransition
	},
	data() {
		return {
			sample: {
				random,
				autocomplete,
				lor,
				dualsubtypes,
				enragedboars,
				esteemedknightdemo,
				flamesweep,
				optionsearch
			},
			tippyConfig: {
				arrow: true,
				placement: "bottom-start",
				theme: "grey",
			},
		};
	},
	methods: {
		copyText(text) {
			navigator.clipboard.writeText(text);
		},
	},
};
</script>

<template>
	<div class="page">
		<b-container fluid class="page__api">
			<b-row>
				<slide-y-up-transition appear :duration=500 mode="out-in">
					<b-col>
						<h4>API Documentation</h4>
					</b-col>
				</slide-y-up-transition>
			</b-row>
			<b-row>
				<slide-x-left-transition appear :duration=500 mode="out-in">
					<b-col lg="3" class="mb-3">
						<div class="sticky-top doc-nav">
							<b-card no-body>
								<b-list-group flush>
									<scrollactive
										active-class="active"
										:offset="25"
										:scroll-offset="10"
										:duration="400"
									>
										<b-list-group-item
											class="scrollactive-item"
											href="#start"
										>
											Getting Started
										</b-list-group-item>

										<b-list-group-item
											class="doc-nav__separator"
										>
											<b-icon-arrow-left-right
												scale="1"
												class="mr-1"
											/>
											Cards
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#cards-search"
										>
											/cards/search
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#cards-autocomplete"
										>
											/cards/autocomplete
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#cards-random"
										>
											/cards/random
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#cards-slug"
										>
											/cards/:slug
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#cards-edition-slug"
										>
											/cards/edition/:editionSlug
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#cards-prefix-collector-number"
										>
											/cards/:prefix/:collectorNumber
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#stats-query"
										>
											Stats Query
										</b-list-group-item>
										
										<b-list-group-item
											class="doc-nav__separator"
										>
											<b-icon-arrow-left-right
												scale="1"
												class="mr-1"
											/>
											Options
										</b-list-group-item>

										<b-list-group-item
											class="
												scrollactive-item
												doc-nav__indent
											"
											href="#option-search"
										>
											/option/search
										</b-list-group-item>

									</scrollactive>
								</b-list-group>
							</b-card>
						</div>
					</b-col>
				</slide-x-left-transition>
				<slide-x-left-transition appear :duration=500 :delay="150" mode="out-in">
				<b-col lg="9">
					<div id="start" class="doc-content">
						<h5>Getting Started</h5>
						<p class="doc-content__body">
							Grand Archive public API allows developers to access
							card data.
							<br /><br />
							API URL:
							<span class="text-code">https://api.gatcg.com</span>
							<br />

							Unless stated otherwise, all API responses will have
							<span class="text-code">Content-Type</span> as
							<span class="text-code"
								>application/json; charset=utf-8</span
							>
							<br />

							<br /><br />
							The API is a work in progress. If you find a
							problem, please report it in the Discord channel.
						</p>
					</div>
					<div id="cards-search" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							cards/search
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Search cards based on the query parameters.
								<br />
								Use the
								<router-link :to="{ name: 'AdvancedSearch' }"
									>Advanced Search page</router-link
								>
								to learn interactively. The API query section
								generates the API URL with parameters
								automatically.
								<br />
								If there are multiple different parameters, the
								result is the cards that match all the
								parameters.
								<br />
								If there are multiple repeating parameters, the
								result can be selected to either match all or
								match any of the repeating parameters.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Parameter</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>
												page
												<div
													class="
														doc-content__table__default
													"
												>
													<code>1</code>
												</div>
											</b-td>
											<b-td>
												The page number to retreive in
												the array of cards.
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>
												page_size
												<div
													class="
														doc-content__table__default
													"
												>
													<code>50</code>
												</div>
											</b-td>
											<b-td>
												The maximum number of cards to
												have in a page. Maximum page
												size is 50.
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>
												sort
												<div
													class="
														doc-content__table__default
													"
												>
													<code
														>collector_number</code
													>
												</div>
											</b-td>
											<b-td>
												The card's attribute to sort the
												cards by. Sortable attributes
												are:
												<code>collector_number</code>,
												<code>rarity</code>,
												<code>name</code>,
												<code>cost_memory</code>,
												<code>cost_reserve</code>,
												<code>level</code>,
												<code>power</code>,
												<code>life</code>,
												<code>durability</code>
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>
												order
												<div
													class="
														doc-content__table__default
													"
												>
													<code>ASC</code>
												</div>
											</b-td>
											<b-td>
												The sort order. Can be
												<code>ASC</code> for ascending
												order or <code>DSC</code> for
												descending order.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>slug</b-td>
											<b-td>
												Get a card with a
												<code>slug</code> that exactly
												match this string
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>name</b-td>
											<b-td>
												Get cards with
												<code>name</code> that is
												similar to this string.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>effect</b-td>
											<b-td>
												Get cards with
												<code>effect</code> that is
												similar to this string.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>flavor</b-td>
											<b-td>
												Get cards with
												<code>flavor</code> that is
												similar to this string.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>rule</b-td>
											<b-td>
												Get cards with
												<code>rule</code> that is
												similar to this string.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>element</b-td>
											<b-td>
												Get cards with an
												<code>element</code> that
												exactly matches this string. If
												multiple
												<code>element</code> parameters
												are specified, get cards that
												exactly match any of the
												specified elements.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>type</b-td>
											<b-td>
												Get cards with a
												<code>type</code> that exactly
												matches this string. If multiple
												<code>type</code> parameters are
												specified, get cards that
												exactly match any of the
												specified types.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>
												type_logic
												<div
													class="
														doc-content__table__default
													"
												>
													<code>OR</code>
												</div>
											</b-td>
											<b-td>
												Use together with
												<code>type</code> to specify
												the logical operator between the
												types. Can be <code>AND</code>,
												<code>OR</code>
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>subtype</b-td>
											<b-td>
												Get cards with a
												<code>subtype</code> that
												exactly matches this string. If
												multiple
												<code>subtype</code> parameters
												are specified, get cards that
												exactly match any of the
												specified subtypes.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>
												subtype_logic
												<div
													class="
														doc-content__table__default
													"
												>
													<code>OR</code>
												</div>
											</b-td>
											<b-td>
												Use together with
												<code>subtype</code> to
												specify the logical operator
												between the subtypes. Can be
												<code>AND</code>,
												<code>OR</code>
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>class</b-td>
											<b-td>
												Get cards with a
												<code>class</code> that exactly
												matches this string. If multiple
												<code>class</code> parameters
												are specified, get cards that
												exactly match any of the
												specified classes.
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>
												class_logic
												<div
													class="
														doc-content__table__default
													"
												>
													<code>OR</code>
												</div>
											</b-td>
											<b-td>
												Use together with
												<code>class</code> to specify
												the logical operator between the
												classes. Can be
												<code>AND</code>,
												<code>OR</code>
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>stats</b-td>
											<b-td>
												Get cards that match the
												numerical stats using a string
												query.
												<b-button
													size="sm"
													href="#stats-query"
													>Read more</b-button
												>
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>edition_slug</b-td>
											<b-td>
												Get a card with an edition's
												<code>slug</code> that exactly
												matches this string
											</b-td>
										</b-tr>

										<b-tr>
											<b-td>collector_number</b-td>
											<b-td>
												Get a card with an edition's
												<code>collector_number</code>
												that exactly matches this string
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>prefix</b-td>
											<b-td>
												Get a card with an edition that belong to the set with
												<code>prefix</code>
												that exactly matches this string
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>illustrator</b-td>
											<b-td>
												Get a card with an edition's
												<code>illustrator</code> that
												exactly matches this string
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>edition_effect</b-td>
											<b-td>
												Get a card with an edition's
												<code>effect</code> that is
												similar to this string
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>edition_flavor</b-td>
											<b-td>
												Get a card with an edition's
												<code>flavor</code> that is
												similar to this string
											</b-td>
										</b-tr>
                                        <b-tr>
											<b-td>legality_format</b-td>
											<b-td>
												Get a card that has legality restriction in certain
                                                <code>gameFormat</code>
											</b-td>
										</b-tr>
                                        <b-tr>
											<b-td>legality_limit</b-td>
											<b-td>
												Specify the amount that legality restriction imposes.
                                                Must also define <code>legality_format</code>
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>204</b-td>
											<b-td>
												No result
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>

								<TextInputCopy
									text="https://api.gatcg.com/cards/search?name=lor"
								/>
								<div class="doc-content__example-caption">
									Get cards with the name containing 'lor'
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.lor)"
								/>

								<br><br>

								<span class="doc-content__body-title">
									Example Request
								</span>

								<TextInputCopy
									text="https://api.gatcg.com/cards/search?subtype=ASSASSIN&subtype=WARRIOR&subtype_logic=AND"
								/>
								<div class="doc-content__example-caption">
									Get cards with both 'ASSASSIN' and 'WARRIOR' subtypes
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.dualsubtypes)"
								/>
							</b-col>
						</b-row>
					</div>
					<div id="cards-autocomplete" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							cards/autocomplete
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Return an array with up to 10 basic cards' data.
								The array is sorted by closest match and then
								alphabetically by card's name.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Parameter</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>name</b-td>
											<b-td>
												The query to search for cards by
												their names.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>204</b-td>
											<b-td> No result found </b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>
								<TextInputCopy
									text="https://api.gatcg.com/cards/autocomplete?name=fire"
								/>
								<div class="doc-content__example-caption">
									Find cards with 'fire' in the name
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.autocomplete)"
								/>
							</b-col>
						</b-row>
					</div>
					<div id="cards-random" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							cards/random
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Return an array with up to 20 random cards.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Parameter</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>
												amount
												<div
													class="
														doc-content__table__default
													"
												>
													<code>20</code>
												</div>
											</b-td>
											<b-td>
												The amount of random cards to
												get. Cannot exceed 20.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>500</b-td>
											<b-td>
												Error. Check if amount is
												greater than 20.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>
								<TextInputCopy
									text="https://api.gatcg.com/cards/random?amount=3"
								/>
								<div class="doc-content__example-caption">
									Get 3 random cards
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.random)"
								/>
							</b-col>
						</b-row>
					</div>

					<div id="cards-slug" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							cards/:slug
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Return a card that matches the given <code>slug</code> exactly.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Parameter</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>:slug</b-td>
											<b-td>
												A string that represent each unique card's name.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>204</b-td>
											<b-td>
												No result
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>
								<TextInputCopy
									text="https://api.gatcg.com/cards/enraged-boars"
								/>
								<div class="doc-content__example-caption">
									Get the card with slug 'enraged-boars'
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.enragedboars)"
								/>
							</b-col>
						</b-row>
					</div>

					<div id="cards-edition-slug" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							cards/edition/:editionSlug
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Return a card with an edition that matches the given <code>editionSlug</code> exactly.
								<br>
								The edition that matches the <code>editionSlug</code> will be in <code>result_editions</code> array.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Parameter</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>:editionSlug</b-td>
											<b-td>
												A string that represent each unique edition name.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>204</b-td>
											<b-td>
												No result
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>
								<TextInputCopy
									text="https://api.gatcg.com/cards/edition/esteemed-knight-demo22"
								/>
								<div class="doc-content__example-caption">
									Get the card with edition slug 'esteemed-knight-demo22'
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.esteemedknightdemo)"
								/>
							</b-col>
						</b-row>
					</div>

					<div id="cards-prefix-collector-number" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							cards/:prefix/:collectorNumber
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Return a card with an edition that matches the given <code>collectorNumber</code> exactly and belongs to the set with the given <code>prefix</code>.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Parameter</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>:prefix</b-td>
											<b-td>
												A string that represents a set prefix that a card's edition belongs to.
											</b-td>
										</b-tr>
										<b-tr>
											<b-td>:collectNumber</b-td>
											<b-td>
												A string that represents the collector number of a card's edition.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>204</b-td>
											<b-td>
												No result
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>
								<TextInputCopy
									text="https://api.gatcg.com/cards/DOAp/067KS"
								/>
								<div class="doc-content__example-caption">
									Get the card with prefix 'DOAp' and collector number '067KS'
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.flamesweep)"
								/>
							</b-col>
						</b-row>
					</div>



					<div id="stats-query" class="doc-content">
						<h5>Stats Query</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Stats query is a string used in
								<a href="#cards-search"
									>/cards/search?stats=[query]</a
								>
								to retrieve cards based on numerical stat
								attributes.
								<br />
								Each condition consists of a stat name, a
								comparison operator, and an integer.
								<br />
								Multiple conditions are joined using a
								semicolon.
								<br />
								Multiple conditions are joined using AND logical
								operator.
							</p>
                           
						</div>
						<b-row>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title"
									>Stat</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>cost_memory</b-td>
											<b-td> Memory Cost </b-td>
										</b-tr>
										<b-tr>
											<b-td>cost_reserve</b-td>
											<b-td> Reserve Cost </b-td>
										</b-tr>
										<b-tr>
											<b-td>level</b-td>
											<b-td></b-td>
										</b-tr>
										<b-tr>
											<b-td>power</b-td>
											<b-td></b-td>
										</b-tr>
										<b-tr>
											<b-td>life</b-td>
											<b-td></b-td>
										</b-tr>
										<b-tr>
											<b-td>durability</b-td>
											<b-td></b-td>
										</b-tr>
										<b-tr>
											<b-td>rarity</b-td>
											<b-td>
												The integer value must be
												between 1 and 8. 1 is the most
												common. 8 is the most rare.
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<span class="doc-content__body-title"
									>Operator</span
								>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>=</b-td>
											<b-td> equal to </b-td>
										</b-tr>
										<b-tr>
											<b-td>&lt;</b-td>
											<b-td> less than </b-td>
										</b-tr>
										<b-tr>
											<b-td>&lt;=</b-td>
											<b-td> less than or equal to </b-td>
										</b-tr>
										<b-tr>
											<b-td>&gt;</b-td>
											<b-td> greater than </b-td>
										</b-tr>
										<b-tr>
											<b-td>&gt;=</b-td>
											<b-td>
												greater than or equal to
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

                                <span class="doc-content__body-title"
									>Special Value</span
								>
                                 <p>
                                    Most stats are valid if the value is 0 or greater. However, some stats have valid negative values to represent abstract concepts.
                                </p>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>cost_memory</b-td>
											<b-td>-1 denotes "X" as the card's memory cost</b-td>
										</b-tr>
                                        <b-tr>
											<b-td>cost_reserve</b-td>
											<b-td>-1 denotes "X" in the card's reserve cost</b-td>
										</b-tr>
										
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Query
								</span>
								<TextInputCopy
									text="stats=power>3;power<=6"
								/>
								<div class="doc-content__example-caption">
									Find cards with power greater than 3 and
									less than or equal to 6
								</div>

								<TextInputCopy text="stats=rarity=1" />
								<div class="doc-content__example-caption">
									Find cards with the editions that have the
									rarity of 1
								</div>

								<TextInputCopy text="stats=level=2" />
								<div class="doc-content__example-caption">
									Find cards with level equal to 2
								</div>
							</b-col>
						</b-row>
					</div>

					<div id="option-search" class="doc-content">
						<h5>
							<div class="doc-content__req-type">GET</div>
							option/search
						</h5>
						<div class="doc-content__subtitle">
							<p class="">
								Return an object of possible search values.
							</p>
						</div>
						<b-row>
							<b-col class="doc-content__body">

								<span class="doc-content__body-title">
									Response Status
								</span>
								<b-table-simple class="doc-content__table">
									<b-tbody>
										<b-tr>
											<b-td>200</b-td>
											<b-td> OK </b-td>
										</b-tr>
										<b-tr>
											<b-td>500</b-td>
											<b-td>
												Error
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-col>
							<b-col class="doc-content__body">
								<span class="doc-content__body-title">
									Example Request
								</span>
								<TextInputCopy
									text="https://api.gatcg.com/option/search"
								/>
								<div class="doc-content__example-caption">
									Get all searchable values.
								</div>

								<span class="doc-content__body-title"
									>Example Response</span
								>
								<Code
									lang="json"
									:code="JSON.stringify(sample.optionsearch)"
								/>
							</b-col>
						</b-row>
					</div>


				</b-col>
				</slide-x-left-transition>
			</b-row>
		</b-container>
	</div>
</template>

<style lang="scss">
@import "/public/css/vars.scss";

.doc-nav {
	top: 10px;
	.card-header {
		border-bottom-width: 0;
	}

	&__separator {
		background: #444;
	}
	&__indent {
		padding-left: 35px;
	}
	.list-group-item:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	.list-group-item:last-child {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	.list-group-item {
		font-size: 0.9rem;
	}
}

.doc-content {
	$grey-text: rgb(163, 163, 163);
	margin-bottom: 40px;
	padding-top: 15px;
	border-top: 1px solid #444;
	h5 {
		margin-bottom: 5px;
	}
	&__subtitle {
		width: 100%;
		margin-bottom: 7px;
		p {
			line-height: 1.7rem;
			margin-bottom: 0px;
		}
	}
	&__req-type {
		font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
			"Courier New", monospace !important;
		margin-right: 10px;
		background: #0e0e0e;
		padding: 3px 10px 1px;
		line-height: 0.9rem;
		font-size: 0.9rem;
		border-radius: 3px;
		width: fit-content;
		float: left;

		color: rgb(74, 197, 2);
		border: 1px solid rgb(57, 151, 2);
	}
	&__body {
		color: $grey-text;
	}
	&__body-title {
		font-size: 0.7rem;
		text-transform: uppercase;
		color: #fff;
	}
	&__table {
		color: $grey-text;
		td {
			border-color: #444;
		}

		td:first-child {
			width: 30%;
		}

		&__default {
			font-size: 0.9rem;
			line-height: 0.9rem;
		}
		&__default::before {
			content: "default: ";
			font-size: 0.7rem;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
	&__example-caption {
		margin-top: 10px;
		margin-left: 7px;
		margin-bottom: 20px;

		font-size: 0.9rem;
		line-height: 0.9rem;
	}

	.text-code {
		background: #0e0e0e;
		padding: 3px 8px;
		margin: 3px;
		border-radius: 3px;
		font-size: 0.9rem;
		line-height: 0.9rem;
		font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
			"Courier New", monospace !important;
		color: $primary-blue;
	}
}
.doc-content:first-child {
	border-top: none;
}
</style>